import React from 'react';
import * as Styles from './BmCart.style';
import classes from 'assets/css/fading-animation.module.css';
import priceCalculator from 'utils/price-calculator';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading/Loading';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import BmCartLastStep from './bm-cart-last-step/BmCartLastStep';
import BmCartStep1 from './bm-cart-step1/BmCartStep1';
import BmCartStep2 from './bm-cart-step2/BmCartStep2';
import BmCartStep3 from './bm-cart-step3/BmCartStep3';
import BmCartStep4 from './bm-cart-step4/BmCartStep4';
import PriceCalculator from '../component/price-calculator/PriceCalculator';
import AppDrawer from 'pages/checkout-member/layout/app-drawer/AppDrawer';
import Header from 'pages/checkout-member/layout/header/Header';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navLinks } from 'utils/nav-links-util';
import BmCartStep5 from './bm-cart-step5/BmCartStep5';
import { totalPrice, yearlyTotalPrice, biYearlyTotalPrice, triYearlyTotalPrice } from 'pages/checkout-member/vs/util';

const BmCart = ({ formikProp, queryClient, isAppDrawerOpen, regionsData, regionLoading }) => {
  const { values, setFieldValue } = formikProp;
  const locations = useLocation();
  let history = useHistory();
  let promocode = locations.pathname.split('public/checkout/bm/')[1];
  const { data: sizeData, isLoading: sizeLoading } = useQuery(`/public/checkout/bm/sizes/${promocode}`, {
    onSuccess: (res) => {
      let findServer = res?.bm_sizes?.find(
        (serverSize) => serverSize?.active && serverSize?.availableregions?.includes(values.region),
      );
      if (findServer?.size) {
        let term = findServer.price_monthly_usd == '' ? 12 : 1;
        setFieldValue('term', term);
        setFieldValue('size', findServer.size);
        setFieldValue('monthly_price.size', findServer.price_monthly_usd);
        setFieldValue('yearly_price.size', findServer.price_yearly_usd);
        setFieldValue('biennial_price.size', findServer.price_biennial_usd);
        setFieldValue('triennial_price.size', findServer.price_triennial_usd);
        setFieldValue('selected_size', findServer);
      }
    },
  });
  const { data: osData, isLoading: osLoading } = useQuery('/public/checkout/bm/os');
  const { data: appsData } = useQuery('/public/checkout/bm/apps');
  const { data: bmUpgrade, isLoading: bmUpgradeLoading } = useQuery(`/public/checkout/bm/upgrades/${values.size}`, {
    enabled: values.size != '',
  });
  const noOfBm = useSelector((state) => state.bmCart.bm_deploy);
  const hanldeNavigation = () => history.replace(navLinks.public_checkout_bm_summary.link);

  const isLoading = sizeLoading || regionLoading || osLoading;

  if (isLoading) {
    localStorage.setItem('promoBmCode', promocode);
    return <Loading gap={200} />;
  }

  return (
    <Styles.MemberNavigatorSC>
      <AppDrawer isAppDrawerOpen={isAppDrawerOpen} checkoutLink={navLinks.public_checkout_bm_summary.link} />
      <Styles.PageHeaderBodyContainer>
        <Header queryClient={queryClient} noOfCartItem={noOfBm} hanldeNavigation={hanldeNavigation} />
        <Styles.PageContainer>
          <Styles.PageContent>
            <Styles.NewVMSC>
              <div className={classes.price_box_show}>
                <PriceCalculator
                  values={values}
                  totalPrice={priceCalculator(totalPrice(values))}
                  yearlyTotalPrice={priceCalculator(yearlyTotalPrice(values))}
                  biYearlyTotalPrice={priceCalculator(biYearlyTotalPrice(values))}
                  triYearlyTotalPrice={priceCalculator(triYearlyTotalPrice(values))}
                  cart_name={'bm'}
                />
              </div>
              <BmCartStep1 sizeData={sizeData} regionsData={regionsData} formikProp={formikProp || {}} />
              <BmCartStep2 serverSizes={sizeData} formikProp={formikProp || {}} />
              <BmCartStep5
                bmUpgradeLoading={bmUpgradeLoading}
                bmUpgrade={bmUpgrade?.bm_upgrades}
                formikProp={formikProp || {}}
              />
              <BmCartStep3 appsData={appsData} formikProp={formikProp || {}} />
              <BmCartStep4 osData={osData} formikProp={formikProp || {}} />
              <BmCartLastStep formikProp={formikProp || {}} />
            </Styles.NewVMSC>
          </Styles.PageContent>
        </Styles.PageContainer>
      </Styles.PageHeaderBodyContainer>
    </Styles.MemberNavigatorSC>
  );
};

export default BmCart;
